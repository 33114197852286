* {
  overflow: visible;
  overscroll-behavior: none;
  box-sizing: border-box;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #fff;
}

html,
body,
#root {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.container {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: rgba(8, 9, 10);
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  flex: 1;
  margin-bottom: 40px;
}

.navbar {
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: rgba(8, 9, 10);
  border-radius: 14px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 4px;
  padding-inline: 20px;
}

.logoText {
  color: white;
  background-color: transparent;
}

.navlist {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.navbutton {
  background-color: transparent;
  color: #fff;
  border: none;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.navbutton:hover {
  color: #aaa;
}

.contentContainer {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  /* padding: 50px 20px; */
  background-color: transparent;
  width: 100%;
  flex: 1;
}

.hero-text {
  max-width: 600px;
  margin-bottom: 20px;
  color: #fff;
}

.hero-text h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

.hero-text p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.hero-text button {
  background-color: #007bff;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
}

.hero-image img {
  max-width: 100%;
  height: auto;
}

.footer {
  background-color: #111;
  color: white;
  padding: 10px;
  position: fixed;
  width: 100%;
  bottom: 0;
  text-align: center;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact {
}