.services {
  padding: 20px;
}

.services ul {
  list-style-type: none;
  padding: 0;
}

.services ul li {
  margin: 10px 0;
}
